

const Cart = () => {

   return (
      <>
         <h1>Cart page</h1>
         <p>Content goes here</p>
      </>
   )
}

export default Cart;